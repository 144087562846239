<template>
    <div>
        <nav-admin></nav-admin>
        <div class="container">
            <div class="row mt-4 mb-2">
                <div class="col-12">
                    <h2 class="h2">Orders</h2>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mb-2">
                    <button v-on:click="changeStage(stages.notPaid)" :class="activeStage == stages.notPaid ? 'btn btn-primary mr-2' : 'btn btn-outline-primary mr-2'">
                        Not Paid <b-badge v-if="countPerStage.length && countPerStage[stages.notPaid]" :variant="activeStage == stages.notPaid ? 'light' : 'primary'">{{ countPerStage[stages.notPaid] }}</b-badge>
                    </button>
                    <button v-on:click="changeStage(stages.pending)" :class="activeStage == stages.pending ? 'btn btn-primary mr-2' : 'btn btn-outline-primary mr-2'">
                        Pending <b-badge v-if="countPerStage.length && countPerStage[stages.pending]" :variant="activeStage == stages.pending ? 'light' : 'primary'">{{ countPerStage[stages.pending] }}</b-badge>
                    </button>
                    <button v-on:click="changeStage(stages.discussign)" :class="activeStage == stages.discussign ? 'btn btn-primary mr-2' : 'btn btn-outline-primary mr-2'">
                        Pre-cancel <b-badge v-if="countPerStage.length && countPerStage[stages.discussign]" :variant="activeStage == stages.discussign ? 'light' : 'primary'">{{ countPerStage[stages.discussign] }}</b-badge>
                    </button>
                    <button v-on:click="changeStage(stages.inProcess)" :class="activeStage == stages.inProcess ? 'btn btn-primary mr-2' : 'btn btn-outline-primary mr-2'">
                        In Process <b-badge v-if="countPerStage.length && countPerStage[stages.inProcess]" :variant="activeStage == stages.inProcess ? 'light' : 'primary'">{{ countPerStage[stages.inProcess] }}</b-badge>
                    </button>
                    <button v-on:click="changeStage(stages.paused)" :class="activeStage == stages.paused ? 'btn btn-primary mr-2' : 'btn btn-outline-primary mr-2'">
                        Paused
                    </button>
                    <button v-on:click="changeStage(stages.canceled)" :class="activeStage == stages.canceled ? 'btn btn-primary mr-2' : 'btn btn-outline-primary mr-2'">
                        Canceled
                    </button>
                    <button v-on:click="changeStage(stages.done)" :class="activeStage == stages.done ? 'btn btn-primary mr-2' : 'btn btn-outline-primary mr-2'">
                        Completed
                    </button>
                    <button v-on:click="changeStage(stages.deleted)" :class="activeStage == stages.deleted ? 'btn btn-primary mr-2' : 'btn btn-outline-primary mr-2'">
                        Deleted
                    </button>
                </div>
            </div>
            
            <div class="row">
                <div class="col-12">
                    
                    <loading :isLoading="isLoading" />

                    <div class="spiner-wrapper" v-if="total == 0 && !spinerOn">
                        <b>Nothing found</b>
                    </div>
                    <b-table
                     v-if="!spinerOn && total > 0"
                     id="orders"
                     :items="orders"
                     :fields="fields"
                     :per-page="orders.length"
                     :current-page="1"
                    >
                        <template v-slot:cell(external_order_id)="data">
                            <router-link :to="{ name: 'Order', params: { order_id: data.item.id }, query: { stage: activeStage, page: current_page}}">{{ data.item.external_order_id }}</router-link>
                        </template>
                        <template v-slot:cell(created_at)="data">
                            {{ convertBrouserTZ(data.item.created_at) }}
                        </template>
                        <template v-slot:cell(package_price)="data">
                            ${{ data.item.package_price }}
                        </template>
                        <template v-slot:cell(progress)="data">
                            <div class="progress-block" v-if="stages.inProcess == data.item.stage || stages.paused == data.item.stage">
                                <div class="progress">
                                    <div class="bar" :style="'width:' + data.item.scored_views / (data.item.package_views / 100) +'%;'"></div>
                                    <span>{{ data.item.scored_views / (data.item.package_views / 100) + '%' }}</span>
                                </div>
                            </div>  
                        </template>
                        <template v-slot:cell(action)="data">
                            <div class="controls">  
                                <button v-if="stages.inProcess == data.item.stage" class="btn btn-secondary float-right mr-2" @click="pausePromotion(data.item.id)">Pause</button>
                                <button v-if="stages.pending == data.item.stage" class="btn btn-danger float-right mr-2" @click="preCancel(data.item.id, data.item.is_send_customer_notification_mode)">Cancel</button>
                                <button v-if="stages.paused == data.item.stage || stages.discussign  == data.item.stage" class="btn btn-danger float-right mr-2" @click="cancelPromotion(data.item.id, data.item.is_send_customer_notification_mode)">Cancel</button>
                                <button v-if="stages.pending == data.item.stage || stages.paused == data.item.stage" class="btn btn-primary float-right mr-2" @click="runPromotion(data.item.id, stages.paused != data.item.stage && data.item.is_send_customer_notification_mode)">Run</button>
                                <button v-if="stages.discussign == data.item.stage" class="btn btn-primary float-right mr-2" @click="updatePreCanceledOrder(data.item.id)">Update</button>
                            </div>
                        </template>
                    </b-table>
                </div> 
                <div v-if="total > take" class="col-12">
                    <b-pagination
                     align="center"
                     v-model="current_page"
                     @change="pageChange"
                     :total-rows="total"
                     :per-page="take"
                    ></b-pagination>
                </div>
            </div>
        </div>
        <pause-window ref="pausePromo" @saved="saved"/>
        <cancel-window ref="cancelPromo" @saved="saved" />
        <pre-cancel-order ref="preCancelOrder" @saved="saved" />
        <start-promo-window ref="startPromo" @saved="saved" />
        <update-pre-canceled-order ref="updatePreCanceledOrder" @saved="saved" />
    </div>
</template>
<script>
    import loading from './../../components/admin/spiner';
    import navAdmin from './../../components/nav-admin';
    import pauseWindow from './PauseOrder';
    import cancelWindow from './CancelOrder';
    import preCancelOrder from './PreCancelOrder';
    import startPromoWindow from './StartPromo';
    import updatePreCanceledOrder from './UpdatePreCanceled';
    import { orderProcessStages } from '../../mixins/order-process-stages.mixin';
    import { timeZone } from './../../mixins/time-zone.mixin';

    export default {
        data() {
            return {
                countPerStage: [],
                spinerOn: true,
                activeStage: 0,
                take: 10,
                total: 0,
                current_page: 1,
                fields: [
                    { key: 'created_at', label: "Date", sortable: true },
                    { key: 'external_order_id', label: "Order No.", sortable: true },
                    { key: 'package_price', label: "Package Price" },
                    { key: 'progress', label: "" },
                    { key: 'action', label: "" }
                ],
                orders: []
            }
        },
        mixins:[orderProcessStages, timeZone],
        mounted() {
            this.current_page = this.$route.query['page'] !== undefined ? +this.$route.query['page'] : this.current_page;
            this.activeStage = this.$route.query['stage'] !== undefined ? +this.$route.query['stage'] : this.activeStage;

            this.getOrders();
            this.getAmountPerStage();
        },
        methods: {
            changeStage(stageId) {
                if(this.activeStage != stageId) {
                    this.$router.replace({ name: "Orders", query: {stage: stageId, page: 1 }});
                }

                this.activeStage = stageId;
                this.current_page = 1;
                this.skip = 0;
                this.getOrders();
            },
            pageChange(page) {
                if(this.current_page != page) {
                    this.$router.replace({ name: "Orders", query: {stage: this.activeStage, page: page} });
                }
                this.current_page = page;
                this.getOrders();
            },
            getOrders() {
                this.total = 0; 
                this.spinerOn = true;
                this.$http({ url: 'orders/' + this.activeStage + "/skip/" + ((this.take * this.current_page) - this.take) + "/take/" + this.take, method: 'GET'})
                    .then(res => {
                        this.orders = res.data.data;
                        this.total = res.data.total;
                        this.spinerOn = false;
                    });
            },
            getAmountPerStage() {
                this.countPerStage = [];
                this.$http({ url: 'orders/count/per/stage', method: 'GET'})
                    .then(res => {
                        for(let i = 0; i < res.data.data_set.length; i++) {
                            this.countPerStage.push(res.data.data_set[i].stage_orders_count);
                        }
                    });
            },
            runPromotion(orderId, showOptions) {
                this.$refs.startPromo.showOptions = showOptions;
                this.$refs.startPromo.orderId = orderId;
                this.$refs.startPromo.show();
            },
            pausePromotion(orderId) {
                this.$refs.pausePromo.orderId = orderId;
                this.$refs.pausePromo.show();
            },
            cancelPromotion(orderId, showOptions) {
                this.$refs.cancelPromo.showOptions = showOptions;
                this.$refs.cancelPromo.orderId = orderId;
                this.$refs.cancelPromo.show();
            },
            preCancel(orderId, showOptions) {
                this.$refs.preCancelOrder.showOptions = showOptions;
                this.$refs.preCancelOrder.orderId = orderId;
                this.$refs.preCancelOrder.show();
            },
            updatePreCanceledOrder(orderId) {
                this.$refs.updatePreCanceledOrder.orderId = orderId;
                this.$refs.updatePreCanceledOrder.show();
            },
            saved() {
                this.getOrders();
                this.getAmountPerStage();
            }
        },
        components: {
            navAdmin,
            pauseWindow,
            cancelWindow,
            preCancelOrder,
            startPromoWindow,
            updatePreCanceledOrder,
            loading
        }
    }
</script>
<style lang="scss" scoped>
.modal-json-view {
    height: 900px;
    overflow: scroll;
}

.progress-block {
    min-width: 200px;
}

.progress {
    position: relative;
    height: 32px;
    margin-top: 4px;
}

.bar {
    z-index: 1;
    position: absolute;
    height: 100%;
    background: rgb(110, 110, 255);
}

.progress span {
    position: absolute;
    top: 0;
    z-index: 2;
    text-align: center;
    width: 100%;
    color: black;
    line-height: 32px;
}

.spiner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

</style>
