<template>
    <div v-if="isLoading" class="spiner-wrapper">
        <b-spinner class="d-flex justify-content-center mb-3" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
</template>
<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="scss" scoped>
    .spiner-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0;
    }
</style>